<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="event">
      <Header :event="event" focused/>
      <v-card-title class="headline">
        Configure Design
      </v-card-title>
      <v-card-text>   
        <p>Customize the design for this event.</p>
      </v-card-text>   

      <v-alert v-if="!eventUtil.isProPlan()" type="info" tile>
        <div class="d-flex flex-row align-content-center">
          <p class="grow mb-0"><router-link class="white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade to a PRO plan</router-link> to customize the design.</p>
          <v-btn class="shrink white--text" :to="{name: 'eventmanagerLicense', params: {id:event.id}}">Upgrade</v-btn>
        </div>
      </v-alert>
      <v-alert v-if="!event.logo && eventUtil.isProPlan()" type="warning" tile>Upload at least the logo to enable the custom design.</v-alert>

      <v-card-text v-if="event">
        <v-alert v-if="error || !valid" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>

        <v-form ref="form" v-model="valid" lazy-validation>

            <h3>Artwork</h3>
            <v-row>
              <v-col cols="12" md="6" class="py-0">
                <p class="mb-0"><strong>Logo</strong> (optional, square ~ 500 x 500 px)</p>
                <p class="text-muted">This image will be displayed on a white background.</p>
                <EventImageUploadForm :event-id="event.id" :img="event.logo" :disabled="!eventUtil.isProPlan()" type="logo" @change="refreshPreview" />
              </v-col>

              <v-col cols="12" md="6" class="py-0">
                <p class="mb-0"><strong>Header Background</strong> (optional, landscape format ~ 1150 x 300 px)</p>
                <p class="text-muted">This image should not contain textual content and can be resized differently between devices.</p>
                <EventImageUploadForm :event-id="event.id" :img="event.img" :disabled="!eventUtil.isProPlan()" type="image" @change="refreshPreview" />
              </v-col>
              <!-- <v-col cols="12" md="6" class="py-0">
                <p>Custom Map Marker (optional, transparent square ~ 500 x 500 px)</p>
                <EventImageUploadForm :event-id="event.id" :img="event.map_marker" type="map-marker" />
              </v-col> -->
            </v-row>
            <v-dialog v-if="event.img" max-width="800px" content-class="white">
              <template v-slot:activator="{ on }">
                <v-btn outlined color="primary" v-on="on"><v-icon small class="mr-2">fa fa-eye</v-icon> View Preview</v-btn>
              </template>
              <v-card>
                <EventHeader v-if="event.img" :event="event" :title="event.name" />
              </v-card>
            </v-dialog>
            <p v-else class="font-style--italic">Upload at least a header image to see a preview of the event header.</p>

            <v-row>
              <v-col cols="6">
                <h3 class="mt-8">Primary Color</h3>
                <p>Configure your primary brand color. This color will be used throughout the app and web.</p>
                <v-text-field 
                  v-model="event.color" 
                  label="Brand Color" 
                   :disabled="!eventUtil.isProPlan()"
                >
                  <template v-slot:append>
                    <v-menu v-model="showColorPickerMenu" right  :disabled="!eventUtil.isProPlan()" :close-on-content-click="false">
                      <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                      </template>
                      <v-card>
                        <v-card-text class="pa-0">
                          <v-color-picker v-model="colorForBinding" show-swatches mode="hexa" flat />
                        </v-card-text>
                      </v-card>
                    </v-menu>
                  </template>                        
                </v-text-field>
                <v-alert v-if="event.color && (!event.color.startsWith('#') || event.color.length !=7)" type="info" dense>
                  Please select a valid hex color code.
                </v-alert>
              </v-col>
            </v-row>

        </v-form>
      </v-card-text>      

      <v-card-text>   
        <v-btn class="mb-8" block :disabled="!valid || !eventUtil.isProPlan()" color="primary" :loading="$store.getters.isLoading" @click="submit">Save</v-btn>
        <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || 'There is one or more validation issues. Please check the form fields and correct any errors.' }}</v-alert>
      </v-card-text>   
    </v-card>
    
    <SupportButton/>
  </div>      
</template>



<script>
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import eventManagerService from "@/services/eventManagerService";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import EventImageUploadForm from "@/components/EventImageUploadForm";
import SupportButton from '@/components/SupportButton.vue'
import MarkdownEditor from '@/components/MarkdownEditor.vue'
import EventHeader from "@/components/EventHeader.vue";
import RegFieldEditor from "@/components/RegFieldEditor.vue";
import ToggleButtonInput from "@/components/ToggleButtonInput.vue";
import EventUtil from "@/util/eventUtil";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    EventImageUploadForm,
    ToggleButtonInput,
    SupportButton,
    MarkdownEditor,
    EventHeader,
    Header,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      valid: true,
      error: null,
      event: null,
      showColorPickerMenu: false,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.error = null;
        try {
          var success = await this.eventUtil.save();
          if (success) {
            this.$router.push({ name: 'eventmanagerView', params: { id: this.eventUtil.event.id }});
            return;
          }
          else {
            this.error = response.data.msg;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

    async getEvent(id) {
      var response = await eventManagerService.get(id);
      this.event = response.data;
    },

    async refreshPreview() {
      await this.getEvent(this.$route.params.id);
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },

  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event);
    },

    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Design', disabled: true },
      ];
    },
    colorForBinding: {
      get() {
        console.log('GET', this.event.color);
        if (!this.event.color || this.event.color.length == 0) {
          return "#FFFFFF";
        }
        return this.event.color;
      },
      set(value) {
        console.log('SET', value);
        if (value && value.length > 7) {
          value = value.substring(0, 7).toUpperCase();
          if (value === '#FFFFFF') {
            value = null;
          }
        }
        this.event.color = value;
        this.$forceUpdate();
      }
    },
    swatchStyle() {
      const { event, showColorPickerMenu } = this
      return {
        backgroundColor: event.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        border: 'solid 1px #ccc',
        borderRadius: showColorPickerMenu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    },

    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  },
};
</script>
<style lang="scss">
</style>

